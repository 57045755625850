<template>
  <div class="mds-modal-body">
<!--     <p class="form-title">Thanks for your interest</p>
    <p class="form-sub-title">
      Give us a little information about yourself, and we'll be in touch. We're
      looking forward to learning more about how we can help you.
    </p>
 -->
    <mds-form size="small">
      <mds-input
        v-bind="formData.name"
        v-model="formData.name.value"
      ></mds-input>
      <mds-input
        v-bind="formData.userName"
        v-model="formData.userName.value"
      ></mds-input>
      <mds-combo-box
        v-bind="formData.country"
        label="Country"
        :data-set="countries"
        v-model="selectedCountry"
        required
      ></mds-combo-box>
      <mds-button 
        size="small"
        variation="primary" 
        v-on:click="onSubmit($event)"
      >
        Submit
      </mds-button>
    </mds-form>
    <lock-screen-loader v-if="loading"/>
  </div>
</template>

<script>
/*import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';*/
import { MdsButton } from "@mds/button";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import MdsComboBox from "@mds/combo-box";
import usersData from "../data/data.json";
import trackEvent, { isExternalUser } from "@/utils/googleAnalytics";
import { events } from "@/config/events";
import { downloadArticleEloquaRegister } from "@/services/formService.js";
import downloadEloquaRegisterData from "@/data/downloadEloquaRegisterData.json"
import { getResearchFileUrl } from "@/services/researchService.js";
import LockScreenLoader from "@/components/Common/LockScreenLoader/LockScreenLoader.vue";
import alertEvent from "@/services/alertEvent";

export default {
  name: "DownloadResearchForm",
  components: {
    MdsForm,
    MdsInput,
    MdsComboBox,
    MdsButton,
    LockScreenLoader
  },
  props: {
    researchPaperTitle: {
      type: String,
      default: "",
      required: true,
    },
    researchPaperUrl: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      formData: {
        name: {
          id: "name",
          name: "name",
          label: "Name",
          required: true,
          error: false,
          errorText: ["Please type your full name"],
          value: "",
        },
        userName: {
          id: "emailAddress",
          name: "emailAddress",
          label: "Email Address",
          required: true,
          error: false,
          errorText: ["Please type your email address"],
          value: "",
        },
        country: {
          id: "country",
          name: "country",
          label: "Country",
          required: true,
          placeholder: "Select a Country",
          error: false,
          errorText: ["Please select the country you are from"],
        },
        subject: {
          id: "subject",
          label: "",
          required: false,
          error: false,
          hidden: true,
          value: "[Morningstar Credit] Research Paper Downloaded",
        },
      },
      countries: usersData.countries,
      selectedCountry: [],
      toggle: this.toggle,
      downloadLocation: "",
      downloadUrl: "",
      loading: false,
    };
  },
  methods: {
    async getResearchPaperPreSignedUrl() {
      this.loading = true;
      await getResearchFileUrl(this.researchPaperUrl?.split("/").pop())
        .then(async (resp) => {
          if (resp?.data?.preSignedUrl) {
            this.downloadUrl = resp?.data?.preSignedUrl;
          } else {
            this.downloadUrl = "";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateForm: function () {
      var isValid = true;
      var reg =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

      if (this.$el.querySelector("#name").value === "") {
        this.formData.name.error = true;
        isValid = false;
      } else {
        this.formData.name.error = false;
      }
      if (this.$el.querySelector("#emailAddress").value === "") {
        this.formData.userName.errorText = ["Please type your email address"];
        this.formData.userName.error = true;
        isValid = false;
      } else if (!reg.test(this.$el.querySelector("#emailAddress").value)) {
        this.formData.userName.errorText = [
          "A valid email address is required",
        ];
        this.formData.userName.error = true;
        isValid = false;
      } else {
        this.formData.userName.error = false;
      }
      if (this.selectedCountry.length === 0) {
        this.formData.country.error = true;
        isValid = false;
      } else {
        this.formData.country.error = false;
      }
      return isValid;
    },
    onSubmit: async function (event) {
      event.preventDefault();
      if (this.validateForm()) {
        await this.getResearchPaperPreSignedUrl();
        if (this.downloadUrl != "") {
          if (isExternalUser(this.formData.userName.value)) {
            this.trackArticleDownloadEvent();
          }
          var postData = {};
          postData[this.formData.name.label] = this.formData.name.value;
          postData[this.formData.userName.label] = this.formData.userName.value;
          postData[this.formData.country.label] = this.selectedCountry;
          postData["Subject"] = "[Morningstar Credit] Research Paper Downloaded";
          postData["Research Paper"] = this.researchPaperTitle;
          const name = this.formData.name.value.split(' ');
          const firstName = name[0];
          const lastName = this.formData.name.value.substring(firstName.length + 1);
          const postEloquaData = downloadEloquaRegisterData;
          postEloquaData["fieldValues"]?.forEach((item) => {
            switch (item.name) {
              case "firstName":
                item.value = firstName?.substring(0, 35);
                break;
              case "lastName":
                if (lastName.length === 0) {
                  item.value = " ";
                } else {
                  item.value = lastName?.substring(0, 35);
                }
                break;
              case "emailAddress":
                item.value = this.formData.userName?.value;
                break;
              case "country":
                item.value = this.selectedCountry?.toString().substring(0, 200);
                break;
              case "researchPaperName":
                item.value = this.researchPaperTitle?.substring(0, 200);
                break;
            }
          });
          downloadArticleEloquaRegister(postEloquaData);
          var apiUrl = `${process.env.VUE_APP_API_BASE_URL}/internalmail`;
          fetch(apiUrl, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(postData),
            headers: {
              "Content-Type": "application/json",
              "Strict-Transport-Security":
                "max-age=31536000; includeSubDomains",
            },
          }).then(function (response) {
            return response.json();
          });

          window.open(this.downloadUrl, "_blank");
          this.$emit("exit", true);
        } else {
          alertEvent.$emit("alert", {
            title: "Error",
            message: "It looks like something went wrong",
          });
        }
      }
    },
    trackArticleDownloadEvent() {
      if (this.$route.name.toUpperCase() == "HOME") {
        this.downloadLocation = "home page";
      } else if (this.$route.name.toUpperCase() == "RESEARCH-LIST") {
        this.downloadLocation = "research list page";
      }
      trackEvent(
        events.event.RESEARCH_ARTICLE_DOWNLOAD,
        events.category.RESEARCH_ARTICLE_DOWNLOAD,
        events.action.CLICK,
        events.label.RESEARCH_ARTICLE_DOWNLOAD,
        {
          article_title: this.researchPaperTitle,
          download_location: this.downloadLocation,
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@mds/constants";
@import "@mds/fonts";
@import "@mds/typography";
@import "@mds/link";

.form-title {
  @include mds-level-3-heading;
  text-align: center;
}

.form-sub-title {
  @include mds-level-6-heading;
  text-align: center;
}

.mds-modal-body {
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 20px;
  max-height: calc(90vh - 79px);
}
</style>